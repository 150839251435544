html,
body,
#root,
.App {
	height: 100vh;
	width: 100vw;
}

.newContainer {
	align-content: left;
	opacity: 1;
	padding: 30px;
}

.newContainer input,
select {
	width: 100%;
}

.input-group-text {
	width: 80px;
}

.alertPopup {
	width: 300px;
	position: absolute;
	top: 0px;
	right: 0px;
	margin: 10px;
	font-size: 14px;
}

.tableContainer {
	max-width: 800px;
	font-size: 11px;
}

.table .thead-light th {
	font-size: 13px;
}

.operatorSelect,
.componentElements {
	margin-top: 30px;
}

button.tableButton {
	font-size: 10px;
}

.hub-header {
	display: flex;
	align-items: center;
}

.backupHubs {
	margin-top: 2rem;
}

.hub-accordion-header {
	display: flex;
	align-items: center;
}

.hub-accordion-header:hover {
	cursor: pointer;
}

.hub-header-id {
	margin-left: auto;
	color: #00000076;
}

.codeBlock {
	padding: 15px 20px 15px 35px;
}

.codeBlock:hover {
	background-color: #f3f3f3;
}

.codeBlock-header > div {
	/* display: flex; */
	color: #00000076;
	font-size: 0.8em;
}

.codeBlock-controls-row {
	display: flex;
}

.codeBlock-controls-select {
	max-width: 14rem;
}

.codeBlock-controls-restore {
	background-color: #ce0058 !important;
}

#codeBlock-code {
	overflow-y: scroll;
	max-height: 50vh;
}

.btn-restore-outline:hover {
	background-color: #ce0058 !important;
	color: white !important;
}

code {
	color: #bcbfbe !important;
}

.login {
	letter-spacing: 0.1em;
	font-family: 'Roboto', sans-serif;
	position: absolute;
	width: 100%;
	max-width: 600px;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 50px;
}

.login input {
	width: 55%;
}

.centered-feedback {
	display: flex !important;
	justify-content: center !important;
}

.timer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.timerText {
	margin-left: 30px;
	font-size: 11px;
}

.timerValue {
	font-size: 18px;
	margin-left: 30px;
	margin-bottom: -7px;
}

.timerFormat {
	font-size: 12px;
	margin: 0px 0px 3px 30px;
}

html,
body,
#root,
.App {
	height: 100vh;
	width: 100vw;
}

.btn-restore-fill:hover {
	background-color: #b1004d !important;
}

.btn-restore-outline:hover {
	background-color: #ce0058;
}

/*SVG ICON SYSTEM*/
.icon {
	display: inline-flex;
	align-self: center;
}

.icon svg,
.icon img {
	height: 1em;
	width: 1em;
	fill: currentColor;
}

.icon.baseline svg,
.icon img {
	top: 0.125em;
	position: relative;
}
