.navbar {
	display: flex;
	align-items: left;
	border-radius: 4px;
	padding: 20px !important;
	justify-content: flex-start !important;
}

.navbar__link:hover {
	transform: translateY(-2px);
	color: #181818;
	text-decoration: none;
}

.navbar__link {
	margin: 1em;
	font-family: 'Roboto', 'Helvetica', sans-serif;
	cursor: pointer;
	text-decoration: none;
	letter-spacing: 0.1em;
	text-indent: 0.3em;
	color: #181818;
	border-bottom: 3px solid transparent;
}

.navbar__link-dropdown > a {
	color: #181818 !important;
}

.navbar__link-dropdown {
	margin: 1rem 3.5rem 1rem 1rem;
	font-family: 'Roboto', 'Helvetica', sans-serif;
	cursor: pointer;
	text-decoration: none;
	letter-spacing: 0.1em;
	text-indent: 0.3em;
	overflow: 'visible';
	z-index: '1';
	border-bottom: 3px solid transparent;
}

.navbar__link--active {
	border-bottom: 3px solid #ce0058;
	transition: border-bottom 0.2s ease-in-out;
}

.navbar-code-vault-brand {
	display: inline-block;
	font-weight: 400;
	margin: 0px 0px 0px 10px;
	padding: 0px 0px 0px 2px;
	font-size: 1.5rem;
	letter-spacing: 0.3rem;
}
